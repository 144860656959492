<template>
  <div v-if="!showDetails" class="storedialog load-dialog">
    <span class="download">
      点击下载模板：
      <a target="_blank" :href="templateUrl"> 库存预出库模板.xlsx </a>
    </span>
    <div class="errtext" v-for="(value, key) in resList" :key="key">
      <i class="el-icon-error"></i> {{ key + " " + value }}
    </div>
    <el-upload v-loading="loading" class="upload-box" ref="upload" action="#" :on-change="handleChange"
      :on-remove="handleRemove" :on-exceed="handleExceed" :file-list="fileList" :http-request="submitUpload"
      :auto-upload="true" drag :limit="1">
      <el-icon style="font-size:3em;">
        <UploadFilled />
      </el-icon>
      <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      <div class="el-upload__tip">
        注：Excel表格里的填写项请严格按照模板内容进行填写
      </div>
    </el-upload>
  </div>

  <el-form v-if="showDetails" :model="querys" ref="querys" label-width="90px" class="search-form">
    <el-row>
      <el-form-item label="出库类型" prop="billType">
        <el-col>
          <el-select v-model="form.billType" @change="changeBillType($event, dict.billTypes)" placeholder="请选择出库类型"
            style="width: 150px">
            <el-option v-for="item in dict.billTypes" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-col>
      </el-form-item>
      <el-form-item label="所属公司">
        <el-col>
          <!-- <el-cascader v-model="companyID" :options="companys" /> -->
          <el-tree-select ref="companys" v-model="form.companyID" :data="companys" clearable check-strictly
            :render-after-expand="false" style="min-width: 200px;" />
        </el-col>
      </el-form-item>
      <el-form-item label="出库单号">
        <el-col>
          <el-input v-model="form.billCode" autocomplete="off" disabled style="width: 150px" placeholder="自动生成">
          </el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="出库时间">
        <el-col>
          <el-input v-model="form.billDate" autocomplete="off" disabled style="width: 160px" placeholder="自动生成">
          </el-input>
        </el-col>
      </el-form-item>

      <!-- <el-form-item label="出网编号">
        <el-col>
          <el-input v-model="form.netCode" autocomplete="off" style="width: 130px" placeholder="请填写出网编号">
          </el-input>
        </el-col>
      </el-form-item> -->
    </el-row>
  </el-form>

  <div v-if="showDetails" class="billsdetial">
    <div class="table tableprint" ref="print" id="print-panel">
      <el-table :data="tableData" stripe border width="1200" size="small">
        <el-table-column type="index" align="center" label="序号" width="50"> </el-table-column>

        <el-table-column label="批次号" width="120">
          <template #default="scope">
            <el-input v-model="scope.row.brandSerialCode" autocomplete="off" size="small" placeholder=""> </el-input>
          </template>
        </el-table-column>

        <el-table-column label="库房" width="105">
          <template #default="scope">
            <el-select v-model="scope.row.storeCode" placeholder="请选择库房" size="small" style="width:100%">
              <el-option v-for="item in dict.stores" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="storeCode" label="库房" width="120">
          </el-table-column> -->
        <el-table-column prop="channel" label="渠道" width="80"> </el-table-column>
        <el-table-column prop="brandName" label="供应商" width="80" show-overflow-tooltip></el-table-column>
        <el-table-column prop="detailName" label="名称" width="120" show-overflow-tooltip> </el-table-column>
        <el-table-column prop="brandModelCode" label="品牌款号" width="100" show-overflow-tooltip> </el-table-column>
        <el-table-column prop="detailCode" label="系统款号" width="150" show-overflow-tooltip> </el-table-column>
        <el-table-column prop="stockNumber" label="数量" width="50" show-overflow-tooltip> </el-table-column>
        <!-- <el-table-column prop="weight" width="60" label="克重"> </el-table-column> -->
        <el-table-column label="克重范围" width="80" show-overflow-tooltip>
          <template #default="scope">
            {{ scope.row.weight }} {{ scope.row.weightMax ? (' - ' + scope.row.weightMax) : '' }}
          </template>
        </el-table-column>
        <el-table-column prop="unitName" label="规格" width="80" show-overflow-tooltip> </el-table-column>
        <el-table-column prop="fittingName" label="辅料" show-overflow-tooltip> </el-table-column>
        <el-table-column prop="remark" label="备注" show-overflow-tooltip> </el-table-column>
      </el-table>
      <el-row type="flex" class="inputfeed" justify="center">
        <el-col :span="5">
          操作：
          <el-input size="mini" v-model="form.handoverName" style="width: 60%" autocomplete="on"></el-input>
        </el-col>
        <el-col :span="5">
          交接：
          <el-input size="mini" v-model="form.reviewerName" style="width: 60%" autocomplete="on"></el-input>
        </el-col>
        <el-col :span="5">
          复核：
          <el-input size="mini" v-model="form.operatorName" style="width: 60%" autocomplete="on"></el-input>
        </el-col>
        <el-col :span="5">
          财务：
          <el-input size="mini" v-model="form.financerName" style="width: 60%" autocomplete="on"></el-input>
        </el-col>
      </el-row>
    </div>
    <div class="billsbutton">
      <el-button @click="onprint"> 打印 <i class="el-icon-printer"> </i> </el-button>
      <el-button type="primary" @click="preExport()"> 导出出库表 <i class="el-icon-arrow-right"> </i> </el-button>
      <el-button type="primary" @click="save()"> 保存出库 <i class="el-icon-arrow-right"> </i> </el-button>
    </div>
  </div>
</template>
<script>
import printJS from "print-js";

import { fetchBillCode, billPreOutExport, submitBillOut } from "@/api/stock";
import { uploader, outReviewer } from "@/api/subupload";
export default {
  name: "outstore",
  emits: ["outDialogWidth"],
  data() {
    return {
      templateUrl: process.env.VUE_APP_BASEURL + "/static/template/stockout.xlsx",
      fileList: [],
      loading: false,
      resList: {},
      dataId: "",
      tableData: [],
      showDetails: false,

      dict: {
        brands: [],
        billTypes: [],
        stores: [],
      },

      //companyID: [],
      form: {
        billType: "", //出库类型
        storeCode: "", //出库库房
        billDate: "", //时间
        billCode: "", //出库单据号
        netCode: "", //出、入网单
        handoverName: "",
        reviewerName: "",
        operatorName: "",
        financerName: "",
        companyID: ''
      },
      companys: []
    };
  },
  methods: {
    //添加文件事件
    handleChange(file, fileList) {
      this.fileList = fileList.slice(-3);
    },
    //删除文件事件
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
    //超出个数事件
    handleExceed(file, fileList) {
      this.$message({
        message: "每次只能上传一个文件",
        type: "warning",
      });
    },
    //上传操作
    submitUpload(file) {
      this.loading = true;
      var dataList = new FormData();
      dataList.append(file.file.name, file.file);
      dataList.append("fileType", "excel");
      dataList.append("dataType", "BillOut");
      this.$refs.upload.clearFiles();

      uploader(dataList)
        .then((res) => {
          if (res.code === 200) {
            this.$message({
              message: "上传成功",
              type: "success",
            });
            this.dataId = res.data[0].id;
          } else {
            this.$message.error("上传失败，请重试");
          }
        })
        .then(() => {
          this.loading = false;
          outReviewer(this.dataId).then(
            (res) => {
              if (res.code === 200) {
                this.tableData = Object.values({ ...res.data });
                this.showDetails = true;
                this.$emit("outDialogWidth", "80%");
              }
            },
            (err) => {
              this.resList = err.data;
            }
          );
        });

      this.fileList = [];
    },
    //获取单据编号
    changeBillType(value, options) {
      let items = options.filter(function (item) {
        return item.name == value;
      });
      if (items && items.length > 0) {
        let that = this;
        fetchBillCode({
          billType: items[0].value,
          prev: items[0].attribute,
        }).then((res) => {
          that.form.billCode = res.data + "-" + that.tableData.length;
          that.form.billDate = that.$util.fmtDate(new Date());
        });
      }
    },
    //打印
    onprint() {
      console.log(this.tableData)
      let printData = [];

      let normalSum = 0; let fitSum = 0;
      for (let i = 0; i < this.tableData.length; i++) {
        let row = this.tableData[i];
        if (row.detailName.indexOf('[辅料]') > -1)
          fitSum++;
        else
          normalSum++;

        printData.push({
          '序号': (i + 1),
          '批次号': (row.brandSerialCode || ''),
          '库房': row.storeCode,
          '渠道': row.channel,
          '供应商': row.brandName,
          '名称': (row.detailName || '').length > 13 ? (row.detailName.substr(0, 13) + '…') : (row.detailName || ''),
          '品牌款号': (row.brandModelCode || '无'),
          '数量': row.stockNumber,
          '克重/规格': (row.weight || '无') + "/" + (row.unitName || '无'),
          '辅料': (row.fittingName || ''),
          '备注': (row.remark || ''),
        });
      }

      printJS({
        header: '<h3 class="">预出库单(正常货品 ' + normalSum + ' 件，辅料 ' + fitSum + ' 件)</h3>',
        printable: printData,
        properties: ['序号', '批次号', '库房', '渠道', '供应商', '名称', '品牌款号', '数量', '克重/规格', '辅料', '备注'],
        type: 'json',
        gridHeaderStyle: 'font-size:0.8em;border: 1px solid #ccc;white-space:nowrap',
        gridStyle: 'font-size:0.8em;border: 1px solid #ccc;text-align:center;white-space:nowrap'
      })
    },
    preExport() {
      let that = this;
      if (this.tableData.length <= 0) {
        this.$message({ message: "请导入至少一条数据", type: "warning", });
        return;
      }
      if (this.form.billType == "" || this.form.billDate == "" || this.form.billCode == "") {
        this.$message({
          message: "必填信息不可为空",
          type: "warning",
        });
        return;
      }

      if (this.form.companyID.length == 0) {
        this.$message({ message: "请选择所属公司", type: "warning", });
        return;
      }
      //this.form.companyID = this.companyID[this.companyID.length - 1];

      billPreOutExport({ bill: this.form, bounds: this.tableData }).then((res) => {
        if (res.code == 200)
          window.location.href = process.env.VUE_APP_BASEURL + res.data;
      });
    },
    //出库
    save() {
      let that = this;
      if (this.tableData.length <= 0) {
        this.$message({ message: "请导入至少一条数据", type: "warning", });
        return;
      }
      if (this.form.billType == "") {
        this.$message({ message: "请选择本次出库类型", type: "warning", });
        return;
      }

      if (this.form.companyID.length == 0) {
        this.$message({ message: "请选择所属公司", type: "warning", });
        return;
      }
      //this.form.companyID = this.companyID[this.companyID.length - 1];

      if (this.form.billType == "" || this.form.billDate == "" || this.form.billCode == "") {
        this.$message({ message: "必填信息不可为空", type: "warning", });
        return;
      }

      submitBillOut({ bill: this.form, bounds: this.tableData }).then((res) => {
        that.$message({ type: res.type, message: res.content, });
        this.$emit("outDialogWidth", "");
      });
    },
  },
  mounted() {
    this.$kaung.dictionary("stock", "brand").then((res) => { this.dict.brands = res; });
    this.$kaung.dictionary("stock", "outbound").then((res) => { this.dict.billTypes = res; });
    this.$kaung.dictionary("stock", "store").then(res =>  this.dict.stores = res);

    this.$kaung.companys().then((res) => {
      this.companys = res;
    });
  },
};
</script>
<style lang="less">
.storedialog {
  width: 100%;
  height: 100%;
  min-width: 400px;
  background: #fff;
  text-align: center;

  .download {
    font-size: 20px;
    display: block;
    padding-top: 0px;
  }

  .errtext {
    color: red;
    line-height: 30px;
  }
}</style>